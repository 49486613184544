import { Controller } from '@hotwired/stimulus'
import { mixpanel } from '../application'

// Connects to data-controller="mixpanel-events"
export default class extends Controller {
  static values = {
    eventName: String,
    userId: Number,
    description: String,
  }
  // This will work for most events but will not work for links which are followed. See mixpanel.track_links for that
  track() {
    const eventProperties = {
      distinct_id: this.userIdValue,
    };

    if (this.descriptionValue && this.descriptionValue.trim() !== "") {
      eventProperties.description = this.descriptionValue;
    }
    console.log(eventProperties)
    mixpanel.track(this.eventNameValue, eventProperties);

  }
}
