import { mixpanel } from '../application'

import * as Sentry from '@sentry/browser'
const sentryScope = new Sentry.Scope()
sentryScope.setTag('section', 'Audio Player')

const audioPlayer = () => {
  // ----------------------------------------------------------
  // MAIN DOCUMENT VARIABLES
  const tabs = document.querySelectorAll('.show-document-section-header')

  // Stop process if not in the right page
  if (tabs.length < 1) return

  const content = document.querySelectorAll('.content-item')
  const distinctUserId = document.querySelector('.main-document').dataset.userId
  const documentId = document.querySelector('.document-sortable').dataset.documentId

  // ----------------------------------------------------------
  // AUDIO PLAYER (RENDER) VARIABLES
  const audioPlayer = document.getElementById('audio-player')

  // Audio top row variables
  const hiddenAudioPlayer = document.getElementById('hidden-audio-player')
  const generatingAudioMsg = document.getElementById('generating-audio-message')
  const currentTimeContainer = document.getElementById('current-time')
  const durationContainer = document.getElementById('duration-container')
  const seekSlider = document.getElementById('seek-slider-input')
  let currentTabContent = []
  let nextSectionTab = []
  let nextSectionTabContent = []
  let prevSectionTab = []
  let prevSectionTabContent = []

  // Audio bottom row variables
  const backButton = document.getElementById('back-button')
  const playButton = document.getElementById('play-button')
  const loadingAnimation = document.getElementById('loading-animation')
  const pauseButton = document.getElementById('pause-button')
  const forwardButton = document.getElementById('forward-button')

  // setup variables for audio player
  let playState = 'play'
  let raf = null
  let audioPlayPauseKeysListen = false // Don't listen to audio play and pause events

  // ----------------------------------------------------------
  // AUTOPLAY FEATURE
  // Setup variables
  let audioTab = 0

  // Change tab when audio is finished (Automatic play)
  hiddenAudioPlayer.addEventListener('ended', () => {
    console.log('running auto play')
    console.log('After video end')
    let currentTab = tabs[audioTab]
    let delay = 100
    if (currentTab.dataset.tabDelay !== 'undefined') {
      delay = currentTab.dataset.tabDelay
    }
    const nextTab = tabs[parseInt(audioTab) + 1]

    // Cancel auto-play if next tab has no audio
    if (!nextTab.dataset.tabAudio) return

    setTimeout(() => {
      tabClick(parseInt(audioTab) + 1, 'automatic')
      // override playState
      playState = 'pause'
      // Mixpanel event
      mixpanel.track('Tab changed automatically', { 'distinct_id': distinctUserId, 'description': "A document tab changed automatically, triggered by system logic or user action" })
    }, delay)
  })

  // ----------------------------------------------------------
  // AUDIO SPEED FEATURE
  // has been moved to audio_speed_controller.js

  // ----------------------------------------------------------
  // TEXT OPTIONS
  // has been moved to document_appearance_controller.js

  // ----------------------------------------------------------
  // AUDIO PLAYER AND TAB LOGIC

  // Event listener for each tab

  for (let i = 0; i < tabs.length; i++) {
    //tabs[i].addEventListener('click', () => tabClick(i, 'manual'))
    tabs[i].addEventListener('click', () => tabClick(i, 'automatic'))
  }

  // for (let i = 0; i < tabs.length; i++) {
  //   tabs[i].addEventListener('keydown', (e) => {
  //     tabClick(i, 'manual')
  //     tabNavigation(e)
  //   })
  // }

  // for (let i = 0; i < tabs.length; i++) {
  //   ['click','keydown'].forEach( e =>
  //     tabs[i].addEventListener(e, () => tabClick(i, "manual"))
  //   )
  // }

  // Add focus with keyboard
  // const tabNavigation = () => {
  //   const firstTab = tabs[0]
  //   const lastTab = tabs[tabs.length - 1]

  //   tabs.forEach((tab) => {
  //     tab.addEventListener('keydown', function (e) {
  //       if ((e.code === 'ArrowRight' && e.shiftKey) || (e.code === 'ArrowLeft' && e.code === e.shiftKey)) {
  //         return
  //       } else if (e.code === 'ArrowUp' || e.code === 'ArrowLeft') {
  //         e.preventDefault()
  //         if (tab === firstTab) {
  //           lastTab.focus()
  //         } else {
  //           const focusableElement = tabs.indexOf(tab) - 1
  //           tabs[focusableElement].focus()
  //         }
  //       } else if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
  //         e.preventDefault()
  //         if (tab == lastTab) {
  //           firstTab.focus()
  //         } else {
  //           const focusableElement = tabs.indexOf(tab) + 1
  //           tabs[focusableElement].focus()
  //         }
  //       }
  //     })
  //   })
  // }

  // Tab change function ---------------------------------------------------------------
  function tabClick(tabIndex, tabClickType) {
    let currentTab = tabs[tabIndex]
    let currentContent = content[tabIndex]
    console.log("currentTab", currentTab)
    let currentTabHeader = currentTab.dataset.tabHeader
    let currentTabAudio = currentTab.dataset.tabAudio

    // Hide all content and remove active from all tabs
    removeActive()

    // Set active tab and content
    currentTab.classList.add('active')
    currentTab.focus()
    currentContent.classList.remove('hidden')

    // Hide pause and play button since we will show loading
    // Hide the pause button since we will show loading
    audioPlayPauseKeysListen = false
    pauseButton.classList.add('hidden')
    playButton.classList.add('hidden')
    loadingAnimation.classList.remove('hidden')
    audioPlayer.style.setProperty('--margin-in-audio', '50px')

    // Set the audio player time to 0
    seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
    currentTimeContainer.innerText = calculateTime(seekSlider.value)
    audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)

    // Set audio tab for playing next section automatically
    audioTab = tabIndex
    console.log('redeclared audio tab: ' + audioTab)
    console.log('redeclared audio url: ' + currentTabAudio)


    // Don't show audio player in tables and figures, or in tabs with no audio
    if (
      currentTabHeader === 'Figures'                    ||
      currentTabHeader === 'Tables'                     ||
      currentTabAudio === 'Figures/Tables/Original PDF' ||
      currentTabAudio === ''                            ||
      currentTabAudio === undefined
    ) {
      audioPlayer.classList.add('hidden')
    } else {
      hiddenAudioPlayer.setAttribute('src', currentTabAudio)
      audioPlayer.classList.remove('hidden')
      setAudioSpeed()
      loadAudio(tabClickType)
      console.log('current audio url: ' + currentTabAudio)
    }

    // Mixpanel event
    mixpanel.track('Document Change Tab', { 'distinct_id': distinctUserId, 'description': "User manually switched between tabs in a document" })
  }

  // Listener for keyboard shortcuts
  document.addEventListener('keydown', event => {
    // Remove active from current tab and content
    const removeCurrentActive = (currentTabContent) => {
      currentTab.classList.remove('active')
      currentTabContent.classList.add('hidden')
    }

    // Hide the pause button since we will show loading
    const buttonAnimation = () => {
      audioPlayPauseKeysListen = false
      pauseButton.classList.add('hidden')
      playButton.classList.add('hidden')
      loadingAnimation.classList.remove('hidden')
      audioPlayer.style.setProperty('--margin-in-audio', '50px')
    }

    const currentTab = document.querySelector('.show-document-section-header.active')
    const currentTabAudio = currentTab.dataset.tabAudio
    const focusedElement = document.querySelector(':focus')

    if (focusedElement && event.shiftKey) focusedElement.blur()

    const leftSectionNav = document.querySelector('.left-bar')
    const topSectionNav = document.querySelector('.grouped-section-headers')
    const containerRect = leftSectionNav.getBoundingClientRect()
    const margin = 20

    // Fetching current, next and prev tabs
    for (let i = 0; i < tabs.length; i++) {
      if (currentTab === tabs[i]) {
        currentTabContent = content[i]
        nextSectionTab = tabs[i + 1]
        nextSectionTabContent = content[i + 1]

        prevSectionTab = tabs[i - 1]
        prevSectionTabContent = content[i - 1]
        break
      }
    }

    const scrollToTab = (tab, tabRect) => {
      // Scroll up/down if the tab is above/below the visible area
      if (tabRect.top < containerRect.top) {
        leftSectionNav.scrollTop = tab.offsetTop - leftSectionNav.offsetTop
      } else if (tabRect.bottom > containerRect.bottom) {
        leftSectionNav.scrollTop = tab.offsetTop - leftSectionNav.offsetTop
      }

      // Scroll right/left if the tab is to the right/left of the visible area
      if (tabRect.left < containerRect.left) {
        topSectionNav.scrollLeft = tab.offsetLeft - topSectionNav.offsetLeft - margin
      } else if (tabRect.right > containerRect.right) {
        topSectionNav.scrollLeft = tab.offsetLeft - topSectionNav.offsetLeft - margin
      }
    }

    if (event.code === 'ArrowDown' && event.shiftKey) {
      // Stop process if current tab is last or hidden
      if (nextSectionTab === undefined || (window.getComputedStyle(nextSectionTab).display === 'none')) return

      const nextTabHeader = nextSectionTab.dataset.tabHeader
      const nextTabAudio = nextSectionTab.dataset.tabAudio

      // Hide content and remove active from current tab
      removeCurrentActive(currentTabContent)

      // Set active tab and content for nextTab
      nextSectionTab.classList.add('active')
      nextSectionTab.focus()
      nextSectionTabContent.classList.remove('hidden')

      const nextTabRect = nextSectionTab.getBoundingClientRect()
      scrollToTab(nextSectionTab, nextTabRect)

      // Hide the pause button since we will show loading
      buttonAnimation()

      // Set the audio player time to 0
      seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
      currentTimeContainer.innerText = calculateTime(seekSlider.value)
      audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)

      // Don't show audio player in tables and figures, or in tabs with no audio
      if (nextTabHeader === 'Figures' || nextTabHeader === 'Tables' || nextTabHeader === 'Figures/Tables/Original PDF' || currentTabAudio === '') {
        audioPlayer.classList.add('hidden')
      } else {
        updateAudioPlayer(nextTabAudio)
      }
    } else if (event.code === 'ArrowUp' && event.shiftKey) {
      // Stop process if current tab is first
      if (prevSectionTab == undefined) return

      const prevTabAudio = prevSectionTab.dataset.tabAudio

      // Hide content and remove active from current tab
      removeCurrentActive(currentTabContent)

      // Set active tab and content for previewTab
      prevSectionTab.classList.add('active')
      prevSectionTab.focus()
      prevSectionTabContent.classList.remove('hidden')

      const prevTabRect = prevSectionTab.getBoundingClientRect()

      // Scroll bar automatically if content goes outside the visible area
      scrollToTab(prevSectionTab, prevTabRect)

      // Hide the pause button since we will show loading
      buttonAnimation()

      // update audio player
      updateAudioPlayer(prevTabAudio)
    }
  })

  // Update audio player
  const updateAudioPlayer = (audio) => {
    hiddenAudioPlayer.setAttribute('src', `${audio}`)
    audioPlayer.classList.remove('hidden')
    setAudioSpeed()
    loadAudio('manual')
  }

  // Remove active from all tabs and content

  function removeActive() {
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove('active')
      content[i].classList.add('hidden')
    }
  }

  // ----------------------------------------------------------
  // SET PLAYER SPEED
  const setAudioSpeed = () => {
    // Reset state to play
    playState = 'play'
    hiddenAudioPlayer.playbackRate = audioPlayer.querySelector('.audio-player-box').dataset.audioSpeedPlaybackSpeedValue
  }

  // ----------------------------------------------------------
  // LOAD AUDIO LOGIC

  // This timer will keep trying to reload the audio every 900 ms until the audio is loaded
  const loadAudioTimer = (tabClickType) => {
    // Variable to increment loading time with each trial
    let addedLoadTime = 1000
    console.log('trying to load audio')
    //hiddenAudioPlayer.load()
    setAudioSpeed()
    const customInterval = () => {
      setTimeout(() => {
        console.log(hiddenAudioPlayer.duration)
        if (!isNaN(hiddenAudioPlayer.duration)) {
        //if(true){
          console.log('audio is loaded')
          audioPlayPauseKeysListen = true
          // Hide animation and message
          loadingAnimation.classList.add('hidden')
          audioPlayer.style.setProperty('--margin-in-audio', '20px')
          generatingAudioMsg.classList.add('hidden')

          // Check if the tab has changed automatically to add to play logo
          if (tabClickType === 'manual') {
            playButton.classList.remove('hidden')
          } else if (tabClickType === 'automatic') {
            hiddenAudioPlayer.play()
          }

          // Show time and duration and buffer
          displayDuration()
          setSliderMax()
          // displayBufferedAmount();
          // clearInterval(intervalID);
        } else {
          generatingAudioMsg.classList.remove('hidden')
          audioPlayPauseKeysListen = false
          console.log('audio is not loaded')
          addedLoadTime += 1000
          console.log(addedLoadTime)
          hiddenAudioPlayer.load()
          setAudioSpeed()
          customInterval()
        }
      }, addedLoadTime)
    }

    customInterval()
    // let intervalID = setInterval(() => {
    //   if (!isNaN(hiddenAudioPlayer.duration)) {
    //     console.log(`audio is loaded`);

    //     // Hide animation and message
    //     loadingAnimation.classList.add('hidden');
    //     audioPlayer.style.setProperty('--margin-in-audio', "2rem");
    //     generatingAudioMsg.classList.add('hidden');

    //     // Check if the tab has changed automatically to add to play logo
    //     if (tabClickType === "manual") {
    //       playButton.classList.remove('hidden');
    //     } else if (tabClickType === "automatic") {
    //       hiddenAudioPlayer.play();
    //     }

    //     // Show time and duration and buffer
    //     displayDuration();
    //     setSliderMax();
    //     // displayBufferedAmount();
    //     clearInterval(intervalID);
    //   } else {
    //     console.log(`audio is not loaded`);

    //     hiddenAudioPlayer.load();
    //   }
    // }, 2000);
  }

  // Here we try to load audio and if it fails we try again every 900 ms
  const loadAudio = (tabClickType) => {
    setTimeout(() => {
      console.log('running load audio for audio ')
      if (hiddenAudioPlayer.duration) {
        console.log('audio is not ready yet')
        // Audio still loading so show loading msg
        audioPlayPauseKeysListen = false
        // Load audio with interval and check if it's loaded
        loadAudioTimer(tabClickType)
      } else {
        console.log('audio is loaded')
        // Audio is loaded hide animation and show play button
        loadingAnimation.classList.add('hidden')
        audioPlayer.style.setProperty('--margin-in-audio', '20px')
        audioPlayPauseKeysListen = true
        // Check if the tab has changed automatically to add to play logo
        if (tabClickType === 'manual') {
          playButton.classList.remove('hidden')
        } else if (tabClickType === 'automatic') {
          hiddenAudioPlayer.play()
        }

        // Show time and duration and buffer
        displayDuration()
        setSliderMax()
        // displayBufferedAmount();
      }
    }, 750)
  }

  // ----------------------------------------------------------
  // AUDIO BUTTONS AND INPUTS LOGIC
  let playPromise = undefined
  // audio play button click
  playButton.addEventListener('click', () => {
    playPromise = hiddenAudioPlayer.play()
  })

  // Audio play event from user device (for example keyboard play button)
  hiddenAudioPlayer.addEventListener('play', () => {
    playButton.classList.add('hidden')
    pauseButton.classList.remove('hidden')
    requestAnimationFrame(whilePlaying)
    playState = 'pause'
    // Mixpanel track event
    mixpanel.track('Play Audio', { 'distinct_id': distinctUserId, 'description': 'User played the audio version of a document or section in Audemic' })
  })

  // audio pause button click
  pauseButton.addEventListener('click', () => {
    hiddenAudioPlayer.pause()
  })

  // Audio pause event from user device (for example keyboard pause button)
  hiddenAudioPlayer.addEventListener('pause', () => {
    playButton.classList.remove('hidden')
    pauseButton.classList.add('hidden')
    cancelAnimationFrame(raf)
    playState = 'play'
    // Mixpanel track event
    mixpanel.track('Stop Audio', { 'distinct_id': distinctUserId, 'description': "User stopped the audio playback of a document or section in Audemic" })
  })

  document.addEventListener('keydown', event => {
    // event.preventDefault() // added to prevent scrolling of tabs list caused by space bar press
    event.stopImmediatePropagation()

    let boardOverlayDesktop = document.querySelector('#new-board-overlay-from-highlight-desktop')
    let boardOverlayMobile = document.querySelector('#new-board-overlay-from-highlight')
    let overlayVisible = false

    if (boardOverlayDesktop && boardOverlayMobile) {
      overlayVisible = (boardOverlayDesktop.style.display === 'flex' || boardOverlayMobile.style.height === '100%')
    }

    if (overlayVisible) return

    // prevent audio bar from moving when user is using caret browsing
    if ((event.code === 'ArrowRight' && event.shiftKey) || (event.code === 'ArrowLeft' && event.shiftKey)) {
      return
    } else if (event.code === 'Space' && audioPlayPauseKeysListen) {
      event.preventDefault()
      if (playState === 'pause') {
        if (playPromise !== undefined) {
          // console.log('playPromise is not undefined')
          playPromise.then(() => {
            hiddenAudioPlayer.pause()
          }).catch(error => {
            Sentry.captureException(error, sentryScope)
            console.log(error)
          })
        }
      } else if (playState === 'play') {
        playPromise = hiddenAudioPlayer.play()
      }
    } else if (event.code === 'ArrowLeft') {
      if (hiddenAudioPlayer.currentTime < 5.0) {
        hiddenAudioPlayer.currentTime = 0.0
      } else {
        hiddenAudioPlayer.currentTime -= 5.0
      }
      seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
      currentTimeContainer.innerText = calculateTime(seekSlider.value)
      audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)
    } else if (event.code === 'ArrowRight') {
      hiddenAudioPlayer.currentTime += 5.0
      seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
      currentTimeContainer.innerText = calculateTime(seekSlider.value)
      audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)
    }
  })

  // Go forward 5 seconds button
  forwardButton.addEventListener('click', () => {
    hiddenAudioPlayer.currentTime += 5.0
    seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
    currentTimeContainer.innerText = calculateTime(seekSlider.value)
    audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)
    mixpanel.track('Paper View - Skip 5 Seconds', { 'distinct_id': distinctUserId, 'description': 'User skipped 5 seconds in the paper view' })
  })

  // Go back 5 seconds button
  backButton.addEventListener('click', () => {
    if (hiddenAudioPlayer.currentTime < 5.0) {
      hiddenAudioPlayer.currentTime = 0.0
    } else {
      hiddenAudioPlayer.currentTime -= 5.0
    }
    seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
    currentTimeContainer.innerText = calculateTime(seekSlider.value)
    audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)
  })

  // Slider input logic
  const showRangeProgress = (rangeInput) => {
    if (rangeInput === seekSlider) {
      audioPlayer.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%')
    } else {
      // This is if we add a volume bar
      // audioPlayer.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
    }
  }

  // display time logic
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    let currentTab = tabs[audioTab]
    if(isNaN(minutes)){
      return currentTab.dataset.duration
    }
    return `${minutes}:${returnedSeconds}`
  }

  const displayDuration = () => {
    durationContainer.innerText = calculateTime(hiddenAudioPlayer.duration)
  }

  const setSliderMax = () => {
    let duration = hiddenAudioPlayer.duration
    // let currentTab = tabs[audioTab]
    // if(isNaN(duration)){
    //   duration = currentTab.dataset.duration_in_seconds
    // }
    seekSlider.max = Math.floor(duration)
  }

  const preloadUpcomingAudio = () => {
    let currentTab = tabs[audioTab]
    let maxDuration = seekSlider.max

    if(isNaN(maxDuration)){
      maxDuration = currentTab.dataset.durationInSeconds
    }
    const remainingSeconds = maxDuration - seekSlider.value

    if (remainingSeconds > 15) return

    const nextTab = tabs[parseInt(audioTab) + 1]
    if (!nextTab) return
    if (!nextTab.dataset.tabAudio) return
    if (nextTab.dataset.verbalized === 'true') return
    // Load upcoming audio file. If it exists, it should be cached (TODO: check that it is),
    // if not, it should be generated so that when next section loads it is
    // ready, or at least on the way
    fetch(nextTab.dataset.tabAudio)
      .then(response => {
        if (response.status === 200 || response.status === 204 || response.status === 302) {
          console.log('Preloading upcoming audio')
        } else {
          console.error('Unexpected response status: ' + response.status)
        }
      })
      .catch(error => {
        console.error('Network error: ' + error)
      })

    // Mark the section as verbalized, even if there was an error, because
    // trying more won't solve it
    nextTab.dataset.verbalized = 'true'
  }

  // const displayBufferedAmount = () => {
  //   const bufferedAmount = Math.floor(hiddenAudioPlayer.buffered.end(hiddenAudioPlayer.buffered.length - 1));
  //   audioPlayer.style.setProperty('--buffered-width', `${(bufferedAmount / seekSlider.max) * 100}%`);
  // }
  // // Show buffered audio amount event while playing
  // hiddenAudioPlayer.addEventListener('progress', displayBufferedAmount);

  // Auto scroll content when the highlighted content reaches to the bottom
  const contentAutoScroll = () => {
    const content_target = document.querySelector('.document-section-content-box')
    const highlightedText = document.querySelector('.read-along-highlight')
    const marginFromTop = 50

    if (!highlightedText) return

    const containerRect = content_target.getBoundingClientRect()
    const textRect = highlightedText.getBoundingClientRect()

    // Check if the highlighted text is visible in area of the container
    if (textRect.bottom > containerRect.bottom || textRect.top < containerRect.top) {
      content_target.scrollTop = (highlightedText.offsetTop - content_target.offsetTop) - marginFromTop
    }
  }

  const whilePlaying = () => {
    seekSlider.value = Math.floor(hiddenAudioPlayer.currentTime)
    currentTimeContainer.innerText = calculateTime(seekSlider.value)
    audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`)
    raf = requestAnimationFrame(whilePlaying)
    preloadUpcomingAudio()
    contentAutoScroll()
  }

  seekSlider.addEventListener('input', (e) => {
    console.log('seek slider input event 1 triggered')
    showRangeProgress(e.target)
  })

  // Any change in the input slider will change current time and animate it if playing
  seekSlider.addEventListener('input', () => {
    console.log('seek slider input event 2 triggered')

    currentTimeContainer.innerText = calculateTime(seekSlider.value)
    if (!hiddenAudioPlayer.paused) {
      cancelAnimationFrame(raf)
    }
  })

  seekSlider.addEventListener('change', (e) => {
    console.log('seekSlider change event triggered')
    hiddenAudioPlayer.currentTime = seekSlider.value
    showRangeProgress(e.target)

    if (!hiddenAudioPlayer.paused) {
      requestAnimationFrame(whilePlaying)
    }
  })

  // Run tabClick 0 to load audio
  let tabStartIndex = document.getElementById('default_tab_index').value
  tabClick(tabStartIndex, 'manual')
}

export { audioPlayer }