import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="google-analytics"
export default class extends Controller {
  connect() {
    this.sendDataToGA4()
  }
  
  sendDataToGA4() { 
    if (typeof gtag === 'function') {
      const purchaseData = JSON.parse(this.data.get("ga4"))
      if (purchaseData.synced == false) {
        gtag('event', purchaseData.event_name, purchaseData.ga4)
        }
    }else{
      setTimeout(() => this.sendDataToGA4(), 1000)
    }
  }
}